import Api from "@/services/api";
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        statusIqCompanySettings: {
            "domain": ""
        }
    },
    actions: {
        async getObjects(){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };

            let response = await Api().get(`/Web/SmartRamp/Status/GetObjects`, config)
            .catch(err => {
                console.error(err.message);
            
            });
            return response;
        },
        async getHSProperties({},{objectType:objectType}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            
            let response = await Api().get(`/Web/SmartRamp/Status/GetPipelines/${objectType}`,config)
            .catch(err => {
                console.error(err.message);
            });
            return response;
        },
        async validateUrl({}, Url){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };

            let response = await Api().post(`/Web/SmartRamp/Status/ValidateURL`, Url, config)
            .catch(err => {
                console.error(err.message);
                return err.response;
            });
            return response;
        },
        async getTrackersByCompanyId(){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().get(`/Web/SmartRamp/Status/GetTrackersByCompany`, config)
            .catch(err => {
                console.error(err.message);
            });
            return response.data;
        },
        async createTracker({},{tracker: tracker}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().post(`/Web/SmartRamp/Status/Tracker`, tracker, config)
            .catch(err => {
                console.error(err.message);
                return(err.response);
            });

            return response;
        },
        async deleteTracker({}, {idTracker: idTracker}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().put(`/Web/SmartRamp/Status/ArchiveTracker/${idTracker}`, config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return response.status;
        },
        async getTrackersById({}, {idTracker: idTracker}) {
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().get(`/Web/SmartRamp/Status/GetTrackersById/${idTracker}`, config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return response;
        },
        async updateTracker({}, {tracker: tracker}) {
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().put(`/Web/SmartRamp/Status/UpdateTracker`, tracker, config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return response;
        },        
        async fetchStatusIqCompanySettings({ commit }){
            let authToken = await Vue.prototype.$auth.getTokenSilently();

            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };

            const companySettings = await Api().get(`/Web/SmartRamp/Status/CompanySettings`, config).catch(err => {
                console.error(err.message);
                return false;
            });
            
            commit("SET_STATUS_IQ_COMPANY_SETTINGS", companySettings.data);
        },
        async saveCompanySettings({ commit }, companySettings){
            let authToken = await Vue.prototype.$auth.getTokenSilently();

            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };

            await Api().put(`/Web/SmartRamp/Status/CompanySettings`, companySettings, config).catch(err => {
                console.error(err.message);
                return false;
            });
            
            await this.fetchStatusIqCompanySettings();
        }
    },
    mutations: {
        SET_STATUS_IQ_COMPANY_SETTINGS(state,companySettings){
            state.statusIqCompanySettings = companySettings;
        }
    },
    getters: {
        getStatusIqCompanySettings: state => { return state.statusIqCompanySettings }     
    }
}