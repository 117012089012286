<template>
    <v-container>
        <v-overlay
            :absolute="absolute"
            :value="overlayNotHubspot"
        >
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-card class="pa-3" outlined elevation="10" light color="">
                            <v-card-title>
                                You're Not Connected to Hubspot
                            </v-card-title>
                            <v-card-subtitle>
                                To create Trackers you'll need to connect.
                            </v-card-subtitle>
                            <v-divider></v-divider>
                            <v-card-actions class="d-flex justify-center">
                                <v-btn color="success" x-large class="ml-2" @click="hubspotConnect"><v-icon class="iq_light--text">$hubspotIcon</v-icon >Connect to Hubspot</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-overlay>
        <v-overlay
            :absolute="absolute"
            :value="overlayNoTrackers && this.overlayNotHubspot === false"
        >
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-card class="pa-3" outlined elevation="10" light color="">
                            <v-card-title>
                                You have no current trackers
                            </v-card-title>
                            <v-card-subtitle>
                                Create your first tracker now
                            </v-card-subtitle>
                            <v-divider></v-divider>
                            <v-card-actions class="d-flex justify-center">
                                <v-btn color="primary" x-large class="ml-2" @click="goCreate"><v-icon class="iq_light--text">mdi-plus</v-icon >Create New Tracker</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-overlay>

        <v-card flat>
            <v-card-text class="pa-0">
                <v-row class="d-flex align-center mb-1">
                    <v-col md="6" cols="12" class="d-flex align-center">
                        <h1>Trackers</h1>  
                        <v-btn small color="primary" @click="goCreate" class="ml-4" v-if="canCreateAdditionalTrackers()">
                            <v-icon  class="iq_light--text">mdi-plus</v-icon ><span>Create Tracker</span>
                        </v-btn>
                    </v-col>   
                    <v-col md="6" cols="12" class="text-right d-flex">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search trackers..."
                            single-line
                            hide-details
                            class="mt-0 pt-2"
                        ></v-text-field>
                      
                    </v-col>
                </v-row>

                <v-data-table
                    :headers="trackerHeaders"
                    :items="trackers"
                    :items-per-page="30"
                    dense
                    fixed-header
                    class="elevation-1 fill-height"
                    :footer-props="{
                        itemsPerPageOptions: [15,30,50,100,200, -1]
                    }"
                >   
                <template v-slot:item.url="{item}">
                    <a target="_blank" :href="item.url">{{item.url}}</a>
                </template>
                <template v-slot:item.actions="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <!-- <v-btn 
                                    x-small
                                    outlined 
                                    color="primary" 
                                    @click="goEdit(item)" 
                                    class="ml-2"
                                >
                                    <span>Edit</span>
                                </v-btn> -->
                             <v-btn
                                x-small
                                outlined
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                >
                                    Actions <v-icon small>mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                v-for="(action, index) in actions"
                                :key="index" 
                                @click="callAction(action.title, item)"
                                :data-user="item.tagName + item.taggedContacts"
                                >
                                    <v-list-item-title>{{ action.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>      
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
    import { mapGetters } from 'vuex';

    export default {
        data () {
            return {
                overlayNotHubspot: false,
                overlayNoTrackers: false,
                search: '',
                // show:false, // TODO: check if need it 

                absolute: true,

                trackers: [],

                window: { //LAYOUT TOOL: store location when page will need vertical space calculations         )
                    width: 0,
                    height: 0
                },
                actions: [
                    { title: 'Edit'},
                    { title: 'Delete'},
                ],
                tickets:[],
                deals:[]
            }
        },
        created() {
            if(!this.currentCompanyApps.statusIq){
                this.$router.push(`product/plan-selection/StatusIQ`);

            }
            this.$auth.auth0ClientPromise.then(async () => {
                await this.$store.dispatch('getUserCompanyApps','StatusIQ');
                await this.$store.dispatch("status/fetchStatusIqCompanySettings");

                if(this.currentCompany.isStatusIQConnected){
                    this.overlayNotHubspot = false;
                }else{
                    this.overlayNotHubspot = true;
                }
                if(this.$route.query.hsConnected === "success"){
                    this.$swal({
                        icon: 'success',
                        title: 'HubSpot Connected',
                        text: 'You have successfully connected to HubSpot!',
                        toast: true,
                        timer: 3000,
                        position: 'top-end'
                    });
                }else if(this.$route.query.hsConnected === "fail"){
                    this.$swal({
                        icon: 'error',
                        title: 'Error connecting to HubSpot',
                        text: 'There was an error connecting to HubSpot. Please try again later.',
                        toast: true,
                        timer: 3000,
                        position: 'top-end'
                    });
                }

                //LAYOUT TOOL: resize handler
                window.addEventListener('resize', this.handleResize);
                this.handleResize();
                await this.getDeals();
                await this.getTickets();
                this.getTrackers();
            });
        },
        destroyed() { //LAYOUT TOOL: on leave dispose
            window.removeEventListener('resize', this.handleResize);
        },
        computed: {
            ...mapGetters({
                currentCompany: 'company/getCurrentCompany',
                currentCompanyApps: 'company/getCurrentCompanyApps',
                statusIQCompanySettings: 'status/getStatusIqCompanySettings'
            }),
            trackerHeaders() {
                return [
                    {
                        text: "Tracker Name", 
                        value: "urlName",
                        align: "center",
                        filter: this.urlNameFilter,
                    },
                    {
                        text: "URL",
                        value: "url",
                        align: "center",
                    },  
                    {
                        text: "Type",
                        value: "type",
                        align: "center",
                    },  
                    {
                        text: "Pipeline",
                        value: "pipeline",
                        align: "center",
                    },             
                    {
                        text: "Actions",
                        value: "actions",
                        align: "center", 
                        groupable: false,
                        filterable: false,
                        sortable: false
                    }
                ]
            }
        },
        methods: {
            hubspotConnect(){
                window.location.href = process.env.VUE_APP_API_URL + `/api/HubSpotConnect/StatusIQ?companyId=${this.currentCompany.id}`;
            },
            showHubSpotSuccessMessage(){
                this.$swal({
                    icon: 'success',
                    title: 'HubSpot Connected',
                    text: 'You have successfully connected to HubSpot!',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
                this.overlayNotHubspot = false;
            },
            showHubSpotErrorMessage(){
                this.$swal({
                    icon: 'error',
                    title: 'Error connecting to HubSpot',
                    text: 'There was an error connecting to HubSpot. Please try again later.',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
            },
            async getTrackers() {
                let trackers = [];
                let response = await this.$store.dispatch('status/getTrackersByCompanyId');

                let url = this.statusIQCompanySettings.domain !== null && this.statusIQCompanySettings.domain !== '' ? this.statusIQCompanySettings.domain : 'statusiq.io';

                if(response.length) {
                    response.forEach( (tracker) => {
                        trackers.push({ // TODO: change
                            urlName: tracker.url,
                            url: `https://${url}/${tracker.url}`,
                            //TODO: create dev env for statusIQ > url: process.env.VUE_APP_STATUS_IQ_URL + '/' + tracker.url, 
                            type: this.capitalizeName(tracker.objectType.toLowerCase()),
                            pipeline: this.getPipeline(tracker.objectType,tracker.searchValue), 
                            id: tracker.id
                        });
                    });
                }

                this.trackers = trackers;
                if(this.trackers.length === 0 ){
                    this.overlayNoTrackers = true;
                }
            },

            getPipeline(type,property){
                var value = '';
                if(type == 'DEAL'){
                    var index = this.deals.findIndex(x => x.value == property);
                    if (index >= 0){
                        value = this.deals[index].name;       
                    }
                }else{
                    var index = this.tickets.findIndex(x => x.value == property);
                    if (index >= 0){
                        value = this.tickets[index].name;       
                    }
                }
                return value;
            },
            async getDeals(){
                let response = await this.$store.dispatch('status/getHSProperties',{
                     "objectType": 'DEAL'
                });
                if(response.data.results) {
                    response.data.results.map((property) => {
                        this.deals.push({
                            value: property.id,
                            name: property.label
                        });
                    }); 
                }   
            },

            async getTickets(){
                let response = await this.$store.dispatch('status/getHSProperties',{
                     "objectType": 'TICKET'
                });
                if(response.data.results) {
                    response.data.results.map((property) => {
                        this.tickets.push({
                            value: property.id,
                            name: property.label
                        });
                    }); 
                }        
            },

            urlNameFilter(value) {
                // If this filter has no value we just skip the entire filter.
                if (!this.search) {
                    return true;
                }

                // Check if the current loop value (The dessert name)
                // partially contains the searched word.
                return value.toLowerCase().includes(this.search.toLowerCase());
            },
            goCreate() {
                this.$router.push('/StatusIQ/create');
            },
            goEdit(tracker) {
                this.$router.push('/StatusIQ/edit/' + tracker.id);
            },
            callAction(action, tracker){
                action === "Edit" ? this.goEdit(tracker) : this.deleteModal(tracker);
            },
            deleteModal(tracker){
                this.$swal.fire({
                    title: 'Are you sure you want to delete this tracker?',
                    icon: 'question',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Yes',
                    denyButtonText: "No",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.archive(tracker);
                        }
                    })
            },
            showError(title, text){
                this.$swal({
                    icon: 'error',
                    title: title,
                    text: text,
                });
            },
            canCreateAdditionalTrackers(){
                if(this.statusIQCompanySettings !== null){
                    if(this.statusIQCompanySettings.plan.name === "Bronze"){
                        return true;
                    }else{
                        return false;
                    }
                }else{
                    return false;
                }
            },
            capitalizeName(name) {
                return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
            },
            async archive(tracker){
                let response = await this.$store.dispatch('status/deleteTracker', {"idTracker": tracker.id});
                if(response){
                    this.getTrackers();
                    this.$swal({
                        icon: 'success',
                        title: 'Tracker Deleted',
                        text: 'Tracker Successfully Deleted',
                        toast: true,
                        timer: 3000,
                        position: 'top-end'
                    });               
                }else{
                    this.showError('We could not delete this tracker.');
                }
            }
        }
    }
</script>
