<template>
    <v-container >
        <v-card elevation="1" class="fill-height">
            <v-card-title>
                <h3>Create Broadcast</h3>
            </v-card-title>
            <v-card-text class="my-2">
                <v-form  v-model="broadcastValid" id="broadcastForm" ref="broadcastForm" lazy-validation>
                    <v-row class="d-flex flex-column justify-space-between" style="height: 100%">
                        <v-col class="flex-grow-1" >
                            <!-- ******* -->
                            <!--  Name   -->
                            <!-- ******* -->
                            <v-text-field
                                :dense="this.$vuetify.breakpoint.smAndDown"
                                outlined
                                label="Broadcast Name"
                                v-model="broadcastForm.Name"
                                @keydown="[validate]"
                                :rules="[formRules.required]"
                            ></v-text-field>

                            <!-- ******* -->
                            <!-- SEND AS -->
                            <!-- ******* -->
                            <v-select
                                :dense="this.$vuetify.breakpoint.smAndDown"
                                ref="selectedcompanyPhone"
                                outlined
                                :loading="loadingPhones"
                                :items="companyPhones"
                                v-model="broadcastForm.SendAs"
                                item-text="name"
                                item-value="value"
                                label="Send As"
                                @keydown="[validate]"
                                :rules="[formRules.selectPhones]"
                            ></v-select>

                            <!-- ******* -->
                            <!-- TAGS CO -->
                            <!-- ******* -->
                            <v-row class="d-flex" justify="space-between">
                                <v-col cols="10" class="pb-0">
                                    <v-autocomplete
                                        multiple
                                        :dense="this.$vuetify.breakpoint.smAndDown"
                                        ref="selectedTags"
                                        outlined
                                        :loading="loadingTags"
                                        :items="selectectTags"
                                        v-model="tagsSelected"
                                        item-text="name"
                                        item-value="id"
                                        label="Select Tags"
                                        v-on:change='handleChange'
                                        @keydown="[validate]"
                                        :rules="[formRules.selectTags]"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="2" class="pb-0 pl-0">
                                    <v-card-text class="pl-0">
                                        <v-list class="transparent" >
                                            <v-list-item class="min-height text-left px-0">
                                                <v-list-item-title>{{this.contacts}} contact</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-card-text>
                                </v-col>
                            </v-row>


                            <!-- ******* -->
                            <!-- MESSAGE -->
                            <!-- ******* -->
                            <v-row class="d-flex mt-0">
                                <v-col cols="10">
                                    <v-textarea
                                        auto-grow
                                        full-width
                                        outlined
                                        clearable
                                        rows="1"
                                        row-height="32px"
                                        v-model="conversationMessage"
                                        :counter="maxConversationMessageLength"
                                        placeholder="Type a message"
                                        @keydown="[validate]"
                                        :value="conversationMessage"
                                        hide-details="auto"
                                        @click:clear="clearMessage()"
                                    >
                                        <template v-slot:counter>
                                            <span class="mx-1">{{conversationMessage.length}}</span>
                                            <span class="mx-1"> / {{maxConversationMessageLength}}</span>
                                            <span class="d-flex align-center flex-end" v-if="checkForSplits !== ''"> / <b  class="font-weight-bold warning--text ml-1"> {{checkForSplits}} SMS</b></span>
                                        </template>
                                    </v-textarea>
                                </v-col>
                                <v-col cols="2" class="pb-0 pl-0">
                                    <v-menu
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        :nudge-width="200"
                                        offset-x
                                        class="pl-4"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                class=""
                                            >
                                                <v-icon >
                                                    mdi-emoticon-happy-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <VEmojiPicker @select="selectEmoji" />
                                        </v-card>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="mt-10">    
                <v-footer absolute>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-space-between">
                            <div>
                                <v-btn class="mx-2" color="primary" @click="createBroadcast" :disabled="!broadcastValid || this.broadcastForm.Name === null || this.broadcastForm.SendAs === null || Object.keys(this.broadcastForm.tagsList).length === 0 || this.conversationMessage === '' ">
                                    <span>Send Broadcast</span>
                                </v-btn>
                                <v-btn class="mx-2" color="primary"  @click="clear" outlined>Clear Form</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-footer>
            </v-card-actions>
        </v-card>

              
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { VEmojiPicker } from 'v-emoji-picker';
export default {
    data (){
        return {
            companyPhones:[],
            selectectTags: [],
            tags: [],
            contacts: 0,
            tagsSelected: null,

            loadingPhones: false,
            loadingTags: false,

            broadcastForm: {
                CompanyId: null,
                Name: null,
                SendAs: null,
                ContactsQueued: null,
                tagsList: {},
                Message: null
            },

            broadcastValid: true,

            window: { //LAYOUT TOOL: store location when page will need vertical space calculations         )
                width: 0,
                height: 0
            },

            formRules: {
                required: v => !!v || 'Required.',
                selectPhones: (v) => !!v || "'Required.",
                selectTags: (v) => !!v || "'Required.",
                max: v => { if (v) return v.length <= 160 || 'Max 160 characters';    else return false;  },
            },
            select: [(v) => !!v || "Item is required"],
            selectTags: [(v) => !!v || "Item is required"],

            //MainMessage Text rules 
            conversationMessage: "",
            maxConversationMessageLength: 160,

            //emoticon trigger menu floater
            menu: false,
        }
    },
    components: {
        VEmojiPicker,
    },   
    created() {
        this.$auth.auth0ClientPromise.then(async () => {
            this.broadcastValid = false;
            await this.$store.dispatch('getUserCompany');

            //LAYOUT TOOL: resize handler
            window.addEventListener('resize', this.handleResize);
            this.handleResize();

            this.getCompanyPhones();
            this.getTags();
        });
    },
    destroyed() { //LAYOUT TOOL: on leave dispose
        window.removeEventListener('resize', this.handleResize);
    },
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany'
        }),
        checkForSplits(){
            var numberOfSplits = this.conversationMessage.length / this.maxConversationMessageLength;
            var splits = "";
            if( numberOfSplits > 1){
                splits = Math.ceil(numberOfSplits);
            }
            return splits;
        },
    },
    methods: {
        async getCompanyPhones() {
            let companyPhones = [];
            this.loadingPhones = true

            let response = await this.$store.dispatch('company/getPhoneNumbers',{
                "companyId": this.currentCompany.id
            });

            this.loadingPhones = false

            if(response.data) {
                response.data.forEach((phone) => {
                    companyPhones.push({
                        value: phone.phoneNumber,
                        name: phone.name,
                    });
                });

                this.companyPhones = companyPhones;
            }
        },
        async getTags() {
            let tags = [];
            this.loadingTags = true

            let response = await this.$store.dispatch('tag/getTagsByCompanyId',{
                "companyId": this.currentCompany.id
            });

            this.loadingTags = false

            if(response) {
                tags = response;

                this.tags = tags;
                this.selectectTags = response;
            }
        },
        async createBroadcast(){
            this.broadcastForm.Message = this.conversationMessage;
            this.broadcastForm.CompanyId = this.currentCompany.id;
            this.broadcastForm.ContactsQueued = this.contacts;

            let response = await this.$store.dispatch('broadcast/sendBroadcast', {
                "broadcast": this.broadcastForm
            });

            if(response){
                if(response.status === 200){
                    this.$swal({
                        icon: 'success',
                        title: 'Broadcast Created',
                        text: 'Broadcast Successfully Created',
                        toast: true,
                        timer: 3000,
                        position: 'top-end'
                    });
                    this.clear();
                    this.resetValidation();
                } else {
                   this.showError(); 
                }               
            }else{
                this.showError();
            }
        },
        showError(){
            this.$swal({
                icon: 'error',
                title: 'We could not create this broadcast.',
                text: '',
            });
        },
        handleChange() {
            let tagsToAdd = [];
            let contacts = 0
            
            tagsToAdd = this.tags.filter((tag) => this.tagsSelected.some(selectedTag => selectedTag === tag.id))
            tagsToAdd.map((tag) => contacts += tag.contacts)
            this.broadcastForm.tagsList = tagsToAdd;

            this.contacts = contacts;
        },
        selectEmoji(emoji) {
            this.conversationMessage = this.conversationMessage + emoji.data;
        },
        validate(){
            this.$refs.broadcastForm.validate()
        },
        resetValidation () {
            this.$refs.broadcastForm.resetValidation()
        },
        clear(){
            this.broadcastForm = {
                CompanyId: null,
                Name: null,
                SendAs: null,
                ContactsQueued: null,
                tagsList: {},
                Message: null
            };
            this.tagsSelected = null;
            this.conversationMessage = "";
            this.contacts = 0;

            this.resetValidation();
        },
    }
}
</script>